<template>
  <div class="agreement">
    <Header/>
    <div class="content">
      <div v-if="id === '1'">
        <p style="text-align: center;" @click="goxieyi(1)"><strong>云梯数字化人才服务平台用户协议</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">尊敬的用户：</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">在您成为</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">注册用户，使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的服务之前，请您认真阅读</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">《云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">用户协议》（以下简称“协议”），更好地了解我们所提供的服务以及您享有的权利和承担的义务。</span><u><strong>您一旦开始使用云梯数字化人才服务平台服务，即表示您已经确认并接受了本协议中的全部条款。</strong></u>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">本协议系由您（以下简称“用户”或“您”）与</span><span
            style="color: rgb(0, 0, 0);">云梯数字技术有限公司（以下简称“</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(0, 0, 0);">”或“我们”）（公司地址：福州市长乐区文武砂街道数字福建产业园东湖路33号8号研发楼，联系电话：</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">0591-83306356</span><span
            style="color: rgb(0, 0, 0);">）就</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">所订立的相关权利义务规范。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">是一个严肃纯净的招聘服务</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">平台</span><span style="color: rgb(68, 68, 68);">，请您在注册、使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">之前，认真阅读以下条款。</span></p>
        <p style="text-align: left;"><strong>一、注册条款的接受</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">依据《网络安全法》《互联网用户账号信息管理规定》等法律法规的要求，您在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">注册页面完成手机短信验证并勾选同意</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">《云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">用户协议》等相关协议，即表示您已经阅读并且同意与</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">达成本协议，成为</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的用户，并接受本协议中的全部注册条款以及</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goxieyi(2)">《隐私政策》</span><span style="color: rgb(68, 68, 68);">和</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">内公布的包括但不限于</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">等各项协议或规则（统称“平台协议”或“平台规则”）的所有条款的约束，</span><u><strong>包括争议解决条款（详见本协议第十四条）。</strong></u>
        </p>
        <p style="text-align: left;"><strong>二、用户的注册与认证</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1. 申请注册</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号的用户应同时满足下列全部条件：在注册之日以及此后使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务期间必须以招聘和/或求职为目的；在注册之日必须年满16周岁以上。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2. 为了更好地享有</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务，用户应遵守</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">注册机制的要求，向</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供本人真实、准确、最新及完整的资料；注册并认证成为招聘者的用户（包括来自用人单位和人力资源服务机构的用户），应保证及时更新本人提供的单位名称、《人力资源许可证》或/和《劳务派遣许可证》、职务或岗位信息、企业邮箱、招聘授权书、承诺函等相关信息及证明材料，并确保前述信息及证明材料的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通、获取、使用、转发求职者简历等均在使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">期间持续有效；通过认证的用户应保持其招聘账号与对应的授权单位具有唯一性。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. 依照《网络招聘服务管理规定》等相关法律法规的规定，若招聘用户提交的材料或提供的信息不准确、不真实、不规范或有理由怀疑证明材料涉及窃取、伪造的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权拒绝为您提供相关功能。您可能无法使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">及相关服务或在使用过程中部分功能受到限制。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4. 若用户故意提供虚假的身份信息、公司信息进行注册，发布虚假招聘信息或者求职信息、未经招聘企业授权的非以自身招聘为目的获取、使用求职者简历的，视为严重违反本协议，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权暂停或终止该用户账号并停止提供服务。虚假注册、发布虚假信息给</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">造成经济、名誉等任何损失的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">将保留追究该用户法律责任的权利。</span></p>
        <p style="text-align: left;"><strong>三、用户账号及安全</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">1. 用户应对利用账号所进行的一切活动负全部责任，包括任何经由</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">上传、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2. 用户的账号遭到未获授权的使用，或者发生其他任何安全问题时，用户应立即通知</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">。由于用户使用不当或者其他非因</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">导致的账号泄露，进而导致其资料、信息泄露的，由用户承担其不利后果。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">3. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号的所有权归</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">所有，用户完成账号注册程序后，获得</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号的使用权，且该使用权仅属于账号初始注册人。同时，用户不得赠与、借用、租用、转让或售卖</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号或者以其他方式许可他人使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号。其他人不得通过受赠、继承、承租、受让或者其他任何方式使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号。如果我们发现或者有合理理由认为账号使用者并非账号初始注册人，为保障账号安全，我们有权立即暂停或终止向该注册账号提供服务，并有权永久禁用该账号。</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4. 用户不得将账号主动告知第三方或提供给第三方进行使用，例如提供给第三方进行代为购买</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务等。如因此造成其他用户隐私泄露或经济损失以及</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">损失的，用户应当承担全部责任。</span></p>
        <p style="text-align: left;"><strong>四、服务说明</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">1. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">通过互联网为用户提供网络服务，包括在线及离线的相关业务。为使用网络服务，用户应自行配备进入互联网所必需的设备，包括计算机、数据机或其它存取装置，并自行支付登录互联网所需要的费用。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2. 基于风控策略、求职安全的考虑，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可能要求部分用户补充提供材料（包括但不限于企业资质证明、承诺书、业务协议等），具体要求会在相关页面上做明确展示。如用户拒绝提供前述材料，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权视情况暂停或终止向该用户提供部分或全部服务。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">3. 为落实《网络招聘服务管理规定》的核验更新义务，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可能会不定期对部分用户的企业地址、招聘授权等相关信息进行真实性审核以及更新核验(目前，该审核机制包括“环境认证”和“线下审核”)。审核过程中，用户应配合</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">工作人员补充相关资料，包括但不限于营业执照、资质证书/相关业务协议、授权书、被授权人身份证信息、租赁协议/水电费记录等，并允许</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">工作人员对其企业LOGO、办公环境进行审核及拍照备案。若用户拒绝，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权视情况暂停或终止为其提供部分或全部的服务。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4. 对于利用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">从事非法活动，或言行（无论线上或者线下的）严重背离</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">严肃招聘目的的用户，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权视情况暂停或终止为其提供部分或全部的服务。若该行为给</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">造成任何损失的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">保留追究其法律责任的权利。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">5. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权通过拨打电话、发送短信或电子邮件等方式，告知用户</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务相关的广告信息、促销优惠等营销信息，以及邀请用户参与版本测试、用户体验反馈、回访等活动。</span><u><strong>除系统通知或重要信息外，用户可以通过云梯数字化人才服务平台提供的方式选择不接收上述信息</strong></u><span
            style="color: rgb(68, 68, 68);">。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6. 用户应通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">使用相关服务；未经许可，不得通过其他第三方工具或运营平台获取</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">内的任何信息服务，包括但不限于通过第三方软件登录</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号、发布职位、浏览职位、收发简历等。如因用户使用第三方软件导致相关信息泄露的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不承担任何责任，且用户还应承担由此给</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">公司</span><span
            style="color: rgb(68, 68, 68);">造成的损失。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">7. 关于单项服务与第三方服务的特殊约定</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· </span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">及相关服务中包含我们以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联方合法运营的其他单项服务,这些服务在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可能以单独</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">板</span><span
            style="color: rgb(68, 68, 68);">块形式存在,</span><span style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权不时地增加、减少或改动这些特别</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">板</span><span
            style="color: rgb(68, 68, 68);">块的设置及服务。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 用户可以在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">中开启和使用上述单项服务功能。某些单项服务可能需要用户开通注册该项服务的账号，同时接受就该服务特别制订的协议或者其他约束其与该项服务提供者之间的规则。必要时</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">将以醒目的方式提供这些协议、规则供用户查阅。一旦用户开始使用上述服务，则视为其理解并接受有关单项服务的相关协议、规则的约束。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 您在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">中使用第三方提供的软件及相关服务时，除遵守</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">协议外，可能还需同意并遵守第三方的协议、相关规则。如因第三方软件及相关服务产生的争议、损失或损害，由您自行与第三方解决，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">并不就此而对您或任何第三方承担任何责任。您在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">中使用第三方提供的支付服务时，请认真了解该服务的内容，充分阅读并同意相关协议条款。</span>
        </p>
        <p style="text-align: left;"><strong>五、有限责任条款</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">1. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">将尽力为用户提供安全、及时、准确、高质量的服务，但无法对用户使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务的效果做出承诺，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。除非另有约定，否则用户因无法使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务，或使用服务未达到心理预期的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不承担责任。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2. 对于用户通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的服务传送的内容，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">会尽合理努力按照国家有关规定严格审核，但无法完全控制经由软件/网站服务传送的内容，不保证内容的准确性、完整性或品质。因此用户在使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">均不为用户经由软件/网站服务以张贴、发送电子邮件或其它方式传送的任何内容负责。但</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用软件/网站服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. 对于</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的各种第三方广告信息、链接、资讯等（如有），</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不保证其内容的准确性、合法性或可靠性，相关责任由广告主承担；并且，对于用户经由</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务与广告主进行联系或商业往来，完全属于用户和广告主之间的行为，与</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">无关。对于前述商业往来所产生的任何损害或损失，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不承担任何责任。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4. 对于用户上传的照片、资料、证件、视频、内容及图片等，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">已采取相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的用户承担。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5. 用户应对</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">上的其他用户发布的内容自行加以判断，并承担因使用内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">无法且不会对因前述风险而导致的任何损失或损害承担责任。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6. 是否使用软件/网站服务下载或取得任何资料应由用户自行考虑并自负风险，因任何资料的下载而导致的用户电脑系统的任何损坏或数据丢失等后果，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不承担任何责任。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">7. 对于</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">在线上或线下策划、发起、组织或是承办的任何招聘相关的活动（包括但不限于收取费用以及完全公益的活动），</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不对上述招聘效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。由此产生的任何对于用户个人或者他人的人身或者是名誉以及其他损害，应由行为实施主体承担责任。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">8. 对于用户的投诉，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">将尽合理努力进行核实和处理，履行平台的管理义务。根据国家相关法律法规的规定，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权决定是否向公众或被投诉者公开投诉内容。</span></p>
        <p style="text-align: left;"><strong>六、用户的个人信息权利</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">1. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">依法对用户的个人信息及隐私进行保护。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2. 用户对于自己的个人信息享有包括但不限于以下权利：</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 随时查询及请求阅览，但因极少数特殊情况无法查询及提供阅览的除外；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 随时请求补充或更正，但因极少数特殊情况无法补充或更正的除外。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. 有关隐私政策的内容具体详见</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196);" @click="goxieyi(2)">《隐私政策》</span><span style="color: rgb(68, 68, 68);">。</span></p>
        <p style="text-align: left;"><strong>七、用户的平台使用义务</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">1. 本协议所称“平台使用”是指用户使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务所进行的任何行为，包括但不限于注册、登录、认证、账号管理、发布招聘信息、邀约面试以及其他通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">在</span><span style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">所进行的一切行为。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">2. 云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">用户在使用</span><span style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务时，应遵守中国通行法律法规以及适用的特定行业法律法规，包括但不限于《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国就业促进法》《中华人民共和国劳动法》《中华人民共和国劳动合同法》《中华人民共和国商标法》《中华人民共和国著作权法》《全国人民代表大会常务委员会关于维护互联网安全的决定》《中华人民共和国保守国家秘密法》《中华人民共和国电信条例》《互联网信息服务管理办法》《计算机信息网络国际联网安全保护管理办法》《中华人民共和国计算机信息系统安全保护条例》《中华人民共和国计算机信息网络国际联网管理暂行规定》《网络招聘服务管理规定》《计算机信息系统国际联网保密管理规定》《网络信息内容生态治理规定》《互联网用户账号名称管理规定》。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. </span><strong>禁止用户通过云梯数字化人才服务平台制作、发送、复制、发布、传播违反《互联网信息服务管理办法》等国家相关法律法规的内容，包括但不限于：</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 反对宪法所确定的基本原则的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 损害国家荣誉和利益的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 煽动民族仇恨、民族歧视，破坏民族团结的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 散布谣言、扰乱社会秩序、破坏社会稳定的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 侮辱或者诽谤他人，侵害他人合法权益的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或有悖道德、令人反感的内容的；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其他内容的。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4. </span><strong>禁止用户通过云梯数字化人才服务平台制作、发布、传播以下信息：</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 涉及招聘未成年人等内容；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 涉及传销或直销、广告（寻求合作）等内容；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 涉及色情、淫秽等内容；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 涉及违法/政治敏感等内容；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 涉及虚假信息，包括但不限于不真实的公司信息、薪资、个人简历、职位信息等；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 涉及虚假职位，包括但不限于发布的职位信息与其实际招聘的职位不符等；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 其他违反</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">的信息。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5. </span><strong>禁止利用云梯数字化人才服务平台从事损害其他用户或云梯数字化人才服务平台合法权益的行为，包括但不限于：</strong>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 冒充任何人或机构，包含但不限于冒充</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">工作人员或以虚假或不实的方式陈述或谎称与任何人或机构有关系的；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 跟踪或以其它方式骚扰其他用户的；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 未经合法授权截获、篡改、收集、储存或删除他人信息、电子邮件或数据资料，或将已获知的此类资料用于任何非法或不正当目的的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 以任何方式干扰或企图干扰</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的正常运行，或者制作、发布、传播上述干扰</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的工具或方法；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 未能按照</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的流程、规则进行注册、认证或使用服务，违反</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的功能限制或运营策略，或采取任何措施规避前述流程、规则、限制或策略的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 未经</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">许可，使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务或系统获取相关数据或信息的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 其他违反</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">的行为。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6</span><strong>.
          禁止用户从事危害云梯数字化人才服务平台生态的行为，包括但不限于：</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 招聘他人从事违法活动；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 以培训费、服装费等名义骗取求职者财物；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 恶意骚扰其他用户；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 利用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可能存在的漏洞恶意充值、获取道具等虚拟产品或服务；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">以外的任何第三方平台（包括但不限于淘宝、闲鱼等）售卖道具等虚拟产品或服务的行为；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 通过第三方平台或渠道（如淘宝店铺等）购买道具等虚拟产品或服务；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 用户使用虚假身份信息/公司信息进行注册或认证、发布虚假招聘或求职信息、发布含有传销、色情、反动等严重违法内容、对外传播面试聊天等通讯记录、拖欠大量农民工薪资的，均视为严重违反本协议；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 其他违反</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">的行为。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">7. </span><strong>禁止用户利用云梯数字化人才服务平台从事其他的违法行为，包括但不限于：</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 利用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的开聊服务从事欺诈、传销、刷流量、好评、违法物品营销等行为；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 冒用、混淆他人账号的昵称、头像、企业介绍发布招聘信息的，或冒充、利用他人名义对外招聘的；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">· 可能存在拖欠或未依法支付薪资或劳务报酬等可能损害劳动者或劳务人员合法权益的行为；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 未经</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的许可自行或授权、允许、协助任何第三人对平台内的任何信息内容进行非法获取；“非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得数据和信息内容的行为；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 为他人提供自动登录到</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">、代办或协助他人代办身份认证的服务或代售身份认证所需的相关材料或凭据；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">· 其他违反</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">的行为。</span></p>
        <p style="text-align: left;">
          <u><strong>用户违反上述任意一项规定的均构成违约，云梯数字化人才服务平台有权按照本协议第八条的约定对其采取处置措施并追究其违约责任。</strong></u>
        </p>
        <p style="text-align: left;"><strong>八、违约责任</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1. 若</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">查实或有合理理由认为用户违反本协议第七条的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权对其采取删除、屏蔽相关信息、限制其使用部分或全部功能等处理措施；为防止损失的进一步扩大，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权视情况紧急及严重程度，在不经事先通知的情况下暂停或终止向该用户提供部分或全部服务。如用户的违约行为导致</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">遭受任何损失的，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权要求该用户向</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">赔偿全部损失。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2. 如用户的违约行为导致任何第三人对</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提出任何索赔或请求的，用户应当赔偿</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">或其他合作伙伴的损失，包括但不限于赔偿金、律师费、合理的调查费等相关费用。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. 用户投诉其他用户有违法行为或违反本协议的约定，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">造成损失的，投诉人应对</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">承担相应的赔偿责任。</span></p>
        <p style="text-align: left;"><strong>九、关于用户在云梯数字化人才服务平台上传或张贴的内容</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1. 用户在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">上传或张贴的内容（包括但不限于照片、文字、面试经历及心得评价等），视为用户授予</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">及其关联公司免费、非独家的使用权，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权为展示、传播及推广前述张贴内容的目的，对上述内容进行复制、修改、出版等。该使用权持续至用户书面通知</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不得继续使用，且</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">实际收到该等书面通知时止。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2. 因用户上传或张贴的内容侵犯他人权利，导致任何第三方向</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提出侵权或索赔要求的，用户应承担全部责任。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3. 任何第三方对于用户在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">无关。</span></p>
        <p style="text-align: left;"><strong>十、不可抗力</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1. “不可抗力”是指</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">根据本注册条款履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争、黑客袭击、电脑病毒、网络故障等。不可抗力可能导致</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄露等不利后果。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2. 遭受不可抗力事件时，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可中止履行本协议项下的义务直至不可抗力的影响消除为止，并且不因此承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。</span>
        </p>
        <p style="text-align: left;"><strong>十一、通知</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">向其用户发出的通知，将采用系统消息、弹窗、电子邮件或页面公告等形式。本《用户协议》的条款修改或其他事项变更时，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可以以上述形式进行通知。</span></p>
        <p style="text-align: left;"><strong>十二、法律的适用和管辖</strong></p>
        <p style="text-align: left;"><u><strong>本协议的生效、履行、解释及争议的解决均适用中华人民共和国的现行法律，本协议的签订地为中华人民共和国北京市海淀区。若您与云梯数字化人才服务平台发生任何争议，双方应尽量友好协商解决，如协商不成，您同意应将争议提交至本协议签订地的人民法院诉讼解决。</strong></u><span
            style="color: rgb(68, 68, 68);">本协议因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。</span>
        </p>
        <p style="text-align: right;"><span style="color: rgb(68, 68, 68);"> </span></p>
        <p><span style="font-family: 宋体;"> </span></p>
      </div>
      <div v-if="id === '2'">
        <p style="text-align: center;" @click="goxieyi(2)"><strong>隐私政策</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);"> </span></p>
        <p style="text-align: left;"><span style="color: rgb(0, 0, 0);">欢迎您使用</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(0, 0, 0);">产品和服务！</span><span style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(0, 0, 0);">产品和服务是由云梯数字技术有限公司北京分公司（以下简称“我们”）（注册地址：北京市海淀区上地九街9号9号4层413）通过包括但不限于</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(0, 0, 0);">网站（</span><strong>https://www.dasdt.cn</strong><span
            style="color: rgb(0, 0, 0);">）、</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(0, 0, 0);" @click="goxieyi(2)">相关微信开放平台账号或小程序以及随技术发展可能出现的其他新形态等途径向您提供的产品和服务。我们非常重视您的隐私保护和个人信息保护，特制定本《隐私政策》帮助您了解以下内容：</span>
        </p>
        <p style="text-align: left;"><strong>一、引言</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">为了切实保护</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">用户隐私权和个人信息，优化用户体验，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">根据《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》《常见类型移动互联网应用程序必要个人信息范围规定》等法律法规，并参考《信息安全技术</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;"> </span><span style="color: rgb(68, 68, 68);">个人信息安全规范》（GB/T 35273-2020）等国家标准，制定本《隐私政策》。本《隐私政策》适用于我们向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">本《隐私政策》将详细说明每个功能可能收集的个人信息范围、收集目的，收集方式，以及拒绝提供个人信息可能的影响。</span><u><strong>请知悉，如拒绝提供非必要个人信息或附加功能所需信息时，您仍然能够使用云梯数字化人才服务平台基本功能。</strong></u>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">本《隐私政策》将向您说明</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">可能需要调取的设备权限、调用的目的或其对应的业务功能、调用前是否询问以及用户关闭相应权限的方式，</span><u><strong>请知悉，您同意本《隐私政策》后，相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使用到相应业务功能时，另行弹窗再次征得您的同意后开启</strong></u><span
            style="color: rgb(68, 68, 68);">，权限开启后，您还可以随时通过设备设置权限关闭，您不同意开启权限，将不会影响其他非相关业务功能的正常使用。</span>
        </p>
        <p style="text-align: left;">
          <strong>我们希望您在使用云梯数字化人才服务平台服务前仔细阅读并理解本《隐私政策》的所有内容，希望您可以根据自己的理解做出合适的选择。</strong><span
            style="color: rgb(68, 68, 68);">此外，当我们将您的个人信息用于本《隐私政策》未涵盖的用途时，我们会事先征求您的同意。</span>
        </p>
        <p style="text-align: left;"><strong>二、我们如何收集和使用您的个人信息</strong></p>
        <p style="text-align: left;"><strong>(一)基本功能及相关必要个人信息</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">在您使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的以下服务或功能过程中，我们将基于以下基本功能收集您的相关必要个人信息。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.账号注册、登录</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">当您注册、登录</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">时，您需要向我们提供您本人的手机号码，以便我们向您发送短信验证码来验证您的身份是否有效（手机号码是</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">履行国家法律法规关于网络实名制要求的必要信息，若您拒绝提供，我们可能无法为您提供信息发布、简历投递等功能，但不影响您通过游客模式或访问</span><strong>https://www.dasdt.cn</strong><span
            style="color: rgb(68, 68, 68);">浏览、搜索职位）。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">【账号注册】您在注册/登录页面通过短信验证后即注册了</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">平台</span><span style="color: rgb(68, 68, 68);">账号，我们将通过账号为您提供服务。您可使用该账号登录并使用我们提供的平台服务，包括</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">及小程序。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">【第三方账号快捷登录】当您使用第三方软件（例如：微信）上的</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">小程序时，您可以使用该等第三方软件账号快捷登录</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">小程序，您授权我们获取该第三方账号绑定的手机号码，用于关联您的账号并展示您已填写的信息。若您是直接使用第三方软件的账号进行注册的情况下，为满足相关法律法规的网络实名制要求，我们需要您绑定您的手机号码，如您拒绝提供手机号码进行核验，将导致您注册失败，但不影响您使用职位浏览功能。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.完善在线简历</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">对于求职者，在您注册成功后，首先您需要完善</span><u>在线简历（公开），设置您的头像，填写您的姓名、性别、工作年限、出生年月、个人优势、工作经历（公司名称、所在行业、所属部门、在职时间、职位名称、工作内容）、项目经历（项目名称、项目角色、项目时间、项目描述、项目链接）、教育经历（学校、学历、专业、在校时间段）、培训经历（培训中心名称、培训方向、项目时间、项目描述）、职业技能（技能名称、熟练程度）、作品案例（作品名称、作品链接、作品介绍）求职期望（求职类型、期望城市、期望职位、薪资要求、期望行业）、求职状态。</u><span
            style="color: rgb(68, 68, 68);">我们将根据您的以上信息，为您匹配可能合适您的岗位，将您推荐给可能合适的招聘者。您的个人信息（包括</span><u>真实手机号码</u><span
            style="color: rgb(68, 68, 68);">）在您没有主动或同意交换的情况下，不会展示给其他用户。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">您可以在【我的】</span><span
            style="color: rgb(68, 68, 68);">-</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">【隐私设置】中，</span><span
            style="color: rgb(68, 68, 68);">设置在线简历信息隐藏，隐藏后招聘者无法再浏览您的在线简历内容。</span></p>
        <p style="text-align: left;"><strong>(二)附加功能及相关非必要个人信息</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">在您使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的以下附加功能或服务中，我们将基于以下功能和服务收集您的相关非必要个人信息，例如您的</span><strong>位置信息、媒体影音信息</strong><span
            style="color: rgb(68, 68, 68);">等。具体请见我们的以下说明：</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.招聘者身份认证</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">招聘者包括用人单位用户和人力资源服务机构用户（含猎头、人才经纪人等）。对于招聘者，您需要向我们提供您本人</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">的姓名、联系电话、邮箱等</span><span
            style="color: rgb(68, 68, 68);">以证明您可以代表该单位进行招聘，保证招聘的真实性，维护求职者合法权益。如果您提交的单位系首次在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">上发布招聘信息，那么您还需要提供营业执照以验证招聘单位的合法性。如您为人力资源服务机构的招聘者用户，并为其他企业代招，您还需要向我们另行提供《人力资源服务许可证》和代招证明资料，以证明您所在的单位符合资质要求并有权为其他企业招聘。认证通过后，您发布的职位方可正常对外显示。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.投递简历</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">对于求职者，在进行简历投递前，您需要先上传一份附件简历</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">，云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">会收集您的附件简历，以便将其通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">传递至招聘者或通过邮箱发送至招聘者的企业邮箱。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3.个性化推荐服务</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(1)您可以通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">软件内的推荐页，浏览到可能会满足您的需求或期望的招聘信息或求职者。我们会保留您的</span><strong>关键词搜索记录，</strong><span
            style="color: rgb(68, 68, 68);">避免您重复输入搜索内容。同时，我们会基于您提交的</span><u>求职意向或招聘意向</u><span
            style="color: rgb(68, 68, 68);">（如您是求职者，您填写的在线简历，例如</span><u>求职期望、工作经历等</u><span
            style="color: rgb(68, 68, 68);">，或如您是招聘者，您填写发布的职位信息），您的</span><strong>操作记录</strong><span
            style="color: rgb(68, 68, 68);">，您的位置信息，为您推荐、展示您可能感兴趣的或与您较为匹配的职位、求职者。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4.约面试</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">当您在线上与对方达成面试意向，并通过</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的约面试功能成功发出面试邀请后，如对方接受了您的线下面试邀请，您的</span><u>手机号码</u><span
            style="color: rgb(68, 68, 68);">需要向对方进行展示，避免对方在前往面试地点时无法与您取得联系</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">。</span><span style="color: rgb(68, 68, 68);"> </span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5.求职安全及服务质量保障</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">为了增强</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的求职招聘、在线面试等过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，以及保障我们向您提供服务的质量，我们会收集您的以下个人信息：</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(1) 我们仅会基于处理您的投诉举报、申诉、出于提升安全性的考虑，使用您在商务场景下产生的</span><u>通讯信息、媒体影音信息（包括</u><u><strong>语音音频信息视频信息</strong></u><u>）/面试记录</u><span
            style="color: rgb(68, 68, 68);">，包括您的</span><u>手机号码</u><span
            style="color: rgb(68, 68, 68);">、</span><strong>沟通记录</strong><span style="color: rgb(68, 68, 68);">（您知悉并同意，您与</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">人工客服的通话可能会被</span><strong>录音</strong><span
            style="color: rgb(68, 68, 68);">），以及您的身份信息，如</span><u>账号ID、手机号码</u><span
            style="color: rgb(68, 68, 68);">、</span><strong>身份证号</strong><span style="color: rgb(68, 68, 68);">，以处理投诉举报事项和纠纷。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(2)在我们检测到您的账号和行为存在异常或违规，以及您被其他用户举报时，我们可能会要求您提供您的</span><strong>姓名</strong><span
            style="color: rgb(68, 68, 68);">和</span><strong>身份证号</strong><span style="color: rgb(68, 68, 68);">，以验证当前用户是否为您本人。如您拒绝提供上述信息进行身份核验，您将无法正常使用我们的产品及/或服务。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(3)在您授权允许我们打开您的位置权限后，为发现、定位和排查行为异常，我们需要获取或更新您的</span><u>设备标识信息</u><span
            style="color: rgb(68, 68, 68);">、</span><strong>位置信息、操作行为</strong><span
            style="color: rgb(68, 68, 68);">。为了求职者的人身、财产安全，我们将在招聘者身份认证（环境认证）、地址核验、发布/修改职位、通过PC端登录等场景中，收集招聘者的</span><strong>位置信息</strong><span
            style="color: rgb(68, 68, 68);">以核验人企关系，防止作弊风险，保障招聘求职安全。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(4)为了保障软件与服务的安全运行、运营的质量及效率，我们会在您登录、使用过程中，收集您的</span><u>设备信息（包括设备标识信息（IDFA、OAID、Android_id）、设备唯一可识别信息（IMEI、WLAN_MAC）、IP地址、MAC地址、WiFi信息（BSSID、SSID）、网络相关信息（网络接入方式、类型、状态等）、应用信息（指定应用信息、正在运行的进程信息、正在运行的任务列表及其他应用相关信息）、传感器信息、蓝牙信息、设备参数及系统信息（设备型号、操作系统及硬件相关信息）</u><span
            style="color: rgb(68, 68, 68);">。在安全监测场景下，我们会调用设备的</span><strong>加速度传感器</strong><span
            style="color: rgb(68, 68, 68);">，用以检测设备的动/静状态，排查设备异常使用情况，确保产品和服务的运行和运营安全。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(5)为了预防恶意程序、保障运营质量及效率，我们会收集您的服务日志信息（包括您的操作使用信息，如浏览记录、点击记录、搜索查询记录、收藏记录、发布信息、分享信息，以及操作日期和时间）。</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(6)为了便于定位并解决您反馈的软件问题，保障服务质量，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">当您通过网页页面或者小程序页面特定入口（如我的-建议反馈）</span><span
            style="color: rgb(68, 68, 68);">向我们反馈问题时，我们会收集您的服务日志信息以及您在商务场景下产生的其他数据（如通讯信息）。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6.其他</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">（1）剪切板。在复制、分享或打开已复制的链接等场景下，我们需要在</span><strong>本地访问</strong><span
            style="color: rgb(68, 68, 68);">您的剪切板，读取其中包含的口令链接等内容，从而为您实现复制、分享、打开链接等使用便利。</span><strong>我们仅会在读取、识别出剪切板内容为云梯数字化人才服务平台转跳、分享口令时，才会将其上传我们的服务器做校验</strong><span
            style="color: rgb(68, 68, 68);">，以向您反馈您所需的内容或绑定相关权益。</span><strong>请您放心，我们不会收集、存储您的剪贴板其他信息，且单独的剪贴板信息无法识别您的特定身份。</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（2）缩略图。当您在软件内上传图片、视频时，如果您选择从相册中上传，我们需要访问相册中的媒体影音信息（即图片或视频的缩略图），以便您可以阅览与选择上传的图片或视频。前述媒体影音信息（即图片或视频的缩略图）仅在本地访问、展示，不会上传或存储在我们的服务器。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（3）日程表/日历。当您在软件内使用预约面试功能，为了便于您将预约信息添加至您的日程表/日历，我们会在获得您的明示同意后，获得您的日程表/日历。</span><strong>请您放心，我们不会收集、存储您在日程表/日历中添加的其他信息。如您拒绝开启该权限，您将无法使用上述功能，但不影响您使用云梯数字化人才服务平台其他基本功能。</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（4）拨打电话。当您在软件内主动拨打软件内展示的电话号码时，我们需要获得您对拨打电话的授权。</span><strong>如您拒绝同意上述功能，您将无法使用上述功能，但不影响您使用云梯数字化人才服务平台其他基本功能。</strong>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（5）实现统计分析：我们会采取脱敏、去标识化/匿名化处理等不识别您个人身份的前提下对您的简历信息或发布的招聘信息进行综合统计、分析和加工，以帮助您更加便捷地了解职业信息、就业趋势、招聘趋势、薪资待遇、供需情况等信息，以及便于我们分析、评估、改善和提升我们的服务质量和运营活动。</span>
        </p>
        <p style="text-align: left;"><strong>（三）需要您授权的其他情形</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.我们可能会将来自某项服务的信息与来自其他服务所获得的信息结合起来进行综合统计分析，用于为您提供更加精准、个性的产品、服务及/或活动。<br>2.邀请您参与有关我们产品、服务的调查或某些您可能感兴趣的活动等，以评估、改进我们的服务效果，为您呈现更佳的服务体验。如您不希望接收此类邀请信息，您可以按照我们提示的方式选择退订。<br>3.经您同意或授权的或法律法规允许的其他用途。</span>
        </p>
        <p style="text-align: left;"><strong>（四）征得授权同意的例外</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(1)与我们履行法律法规规定的义务相关的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(2)与国家安全、国防安全直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(3)与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(4)与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(6)所收集的个人信息是您自行向社会公众公开的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">(7)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(8)根据您的要求签订和履行合同所必需的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(9)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(10)用于维护</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">(11)法律行政法规规定的其他情形；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《隐私政策》公布的联系方式与我们联系。</span>
        </p>
        <p style="text-align: left;"><strong>三、我们如何使用cookie和同类技术</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">为确保网站正常运转，我们会在您的计算机或移动设备上存储名为cookie的数据文件。cookie通常包含用户身份标识符、城市名称以及一些字符。cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用cookie技术，我们能够为您提供更加周到的服务。我们不会将cookie用于本《隐私政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除cookie。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">有关详情，请参见</span><span
            style="color: rgb(68, 68, 68);">aboutcookies.org。您可以清除计算机上保存的所有cookie，大部分网络浏览器都设有阻止cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于cookie的</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供的服务或功能。您可以通过更改您的浏览器设置限制</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">对cookie的使用。以chrome浏览器为例，您可以在chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清除浏览数据”，选择清除您的cookie。</span>
        </p>
        <p style="text-align: left;"><strong>四、我们如何委托处理、共享、转让或公开披露您的个人信息</strong></p>
        <p style="text-align: left;"><strong>(一)委托处理</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">我们的部分功能可能由外部服务商提供。例如，为您提供的隐私号保护服务，由我们委托的第三方专业服务商来为</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">提供虚拟电话技术支持。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">对我们委托处理个人信息的公司、组织和个人，我们会与其签署协议，约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。</span>
        </p>
        <p style="text-align: left;"><strong>(二)公开披露</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。请您放心，这些统计信息不包含您的任何身份识别信息。我们仅会在以下情形下，公开披露您的个人信息：</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.获得您的明确同意；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span></p>
        <p style="text-align: left;"><strong>(三)例外情形</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.与我们履行法律法规规定的义务相关的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.与国家安全、国防安全直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">3.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6.您自行向社会公众公开的个人信息；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">8.根据个人信息主体要求签订和履行合同所必需的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">9.用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">10.法律法规规定的其他情形。</span></p>
        <p style="text-align: left;"><strong>五、我们如何保存及保护您的个人信息</strong></p>
        <p style="text-align: left;"><strong>(一)保存信息</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.信息存储</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">您在使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账号或主动删除上述信息，我们会及时删除您的个人信息或进行匿名化处理，因法律规定需要留存个人信息的，我们不会再将其用于日常业务活动中。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.保存地域</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">我们承诺，您的个人信息将只存储在位于中华人民共和国境内的服务器上，您的信息不会被我们主动传输到境外。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况，并依法开展安全评估。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3.例外情况</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，超出保留时间后，我们会根据适用法律的要求及时删除您的个人信息或进行匿名化处理。下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（1）为遵守法律法规等有关规定的适用；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">（2）为遵守法院判决、裁定或其他法律程序的规定；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">（3）为遵守相关政府机关或法定授权组织的要求；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">（4）为保护我们及我们的客户、用户或雇员的人身财产安全；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（5）其他合法权益所合理必需的用途。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4.当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、公告等，并在合理期限内删除或匿名化处理您的个人信息。</span>
        </p>
        <p style="text-align: left;"><strong>(二)保护措施</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3.我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《隐私政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求或双方另有约定）。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4.互联网并非绝对安全的环境，使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务时，我们强烈建议您不要使用非</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">推荐的通信方式发送您的信息。您可以通过我们的服务与他人建立联系和相互分享内容。当您通过我们的服务进行沟通交流、分享内容时，您可以自主选择沟通、分享的对象，作为能够看到您的</span><strong>联络方式、交流信息</strong><span
            style="color: rgb(68, 68, 68);">等相关信息的第三方。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5.在使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号发生泄露，请您立即通过本《隐私政策》的“十、如何联系我们”中公布的联系方式与我们联系，以便我们根据您的申请采取相应措施。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请妥善保管账号，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</span>
        </p>
        <p style="text-align: left;"><strong>(三)安全事件通知</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
        </p>
        <p style="text-align: left;"><strong>六、您的权利</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span>
        </p>
        <p style="text-align: left;"><strong>(一)访问和修改您的个人信息</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.访问修改姓名</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">（1）求职者可通过我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历中心</span><span
            style="color: rgb(68, 68, 68);">></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">编辑></span><span
            style="color: rgb(68, 68, 68);">姓名</span><span style="color: rgb(68, 68, 68); font-family: 宋体;">,</span><span
            style="color: rgb(68, 68, 68);">修改您的姓名；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.访问和修改性别</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以通过我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历中心</span><span
            style="color: rgb(68, 68, 68);"> >性别</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">></span><span
            style="color: rgb(68, 68, 68);">,更改性别；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">3.访问和修改简历内容</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以在我的>简历</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">中心</span><span
            style="color: rgb(68, 68, 68);">中修改简历内容；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以在我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">隐私</span><span
            style="color: rgb(68, 68, 68);">设置></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历公开</span><span style="color: rgb(68, 68, 68);">中修改简历隐藏或展示；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以在我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历中心></span><span style="color: rgb(68, 68, 68);">附件简历</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">，</span><span style="color: rgb(68, 68, 68);">增加新的附件简历文件或删除已有的附件简历文件。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">4.访问和修改手机号码</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">用户可以在我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历中心</span><span
            style="color: rgb(68, 68, 68);">></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">编辑个人信息中</span><span
            style="color: rgb(68, 68, 68);">修改手机号</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">，</span><span
            style="color: rgb(68, 68, 68);">更换手机号码。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5.访问和修改微信号</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68); font-family: 宋体;">求职</span><span
            style="color: rgb(68, 68, 68);">者可以在我的></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">简历中心</span><span
            style="color: rgb(68, 68, 68);">></span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">头像旁编辑></span><span style="color: rgb(68, 68, 68);">微信号中修改；</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">6.访问和修改邮箱</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以在我的>简历</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">中心</span><span
            style="color: rgb(68, 68, 68);">>点击</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">头像旁编辑按钮</span><span
            style="color: rgb(68, 68, 68);">>邮箱中添加或修改；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">招聘者可以在</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">企业端>公司信息></span><span
            style="color: rgb(68, 68, 68);">邮箱中添加或修改。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">7.访问和修改头像</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">求职者可以在我的>简历</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">中心</span><span
            style="color: rgb(68, 68, 68);">>点击</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">头像旁编辑按钮</span><span
            style="color: rgb(68, 68, 68);">>头像中查看和修改；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">招聘者可以在我的>姓名>头像中查看和修改。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">8.如果您无法通过上述链接访问该等个人信息，您可以通过本《隐私政策》公布的联系方式与我们联系。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">9.您在使用我们的产品和服务过程中产生的其他个人信息，对于您的合理请求，我们会相应向您提供。如果您想访问您在使用我们的产品和服务过程中产生的其他个人信息，请通过本《隐私政策》的“十、如何联系我们”中公布的联系方式与我们联系。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">10.您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</span>
        </p>
        <p style="text-align: left;"><strong>(二)删除您的个人信息</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">您可以通过“1.访问和修改您的个人信息”中列明的方式删除您的部分信息。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">您可以在搜索>历史搜索中点击删除按钮清除搜索记录，在您主动清除后，我们不会再保留您的搜索记录。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">在以下情形，您可以通过本《隐私政策》公布的联系方式与我们联系，向我们提出删除您的个人信息的请求：</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">3.我们没有征求您的明确同意，收集了您的个人信息。</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4.已实现个人信息处理目的、无法实现或者实现处理目的不再必要。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5.我们停止对您提供服务。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">此外，法律法规另有规定，或达到与您约定或者个人信息处理规则明确的存储期限，或您撤回同意、注销账号等情形下，我们会遵照删除制度，采取措施对您的信息进行删除。</span>
        </p>
        <p style="text-align: left;"><strong>(三)提前获知产品和服务停止运营</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">愿一直陪伴您，若因特殊原因导致</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</span>
        </p>
        <p style="text-align: left;"><strong>(四)响应您的上述请求</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求，我们会在收到您的请求并验证您的用户身份后的15个工作日内响应您的上述请求。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
        </p>
        <p style="text-align: left;"><strong>(五)响应请求的例外</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.与我们履行法律法规规定的义务相关的</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.与国家安全、国防安全直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">3.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4.与刑事侦查、起诉、审判和执行判决等直接相关的；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">5.证据表明您可能存在明显恶意、滥用权利及占用资源、辱骂客服人员等相关情形的；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">6.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">7.您的请求超出了一般技术手段和商业成本可覆盖的范围；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">8.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">9.涉及商业秘密的。</span></p>
        <p style="text-align: left;"><strong>七、我们如何处理未成年人的个人信息</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">非常重视对未成年人信息的保护。基于我们的产品、网站和服务的性质，我们不会故意从16岁以下的人士收集或维护我们产品和服务上的个人信息，并且我们产品和服务的任何部分都不会针对16岁以下的人士。如果您未满16岁，请不要以任何方式使用或访问我们的产品和服务。当我们得知我们无意中收集了16岁以下的人士的个人信息时，我们会根据适用法律法规进行删除或采取其他合适的措施。</span>
        </p>
        <p style="text-align: left;"><strong>八、我们对去世用户的个人信息保护</strong></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">将根据《中华人民共和国个人信息保护法》的相关规定加强对死者个人信息的保护。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">用户（仅限自然人）去世后，其近亲属为了自身的合法、正当利益，可以通过本政策第十条公布的联系方式与我们联系，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。</span>
        </p>
        <p style="text-align: left;"><strong>九、本《隐私政策》的更新</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">我们可能适时修订本《隐私政策》内容。如该等变更会导致您在本《隐私政策》项下权利的实质变化，我们将在变更生效前，通过系统推送、电子邮件、短信或页面公告等方式通知您。</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">若您不同意该等变更应停止使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">产品和服务，或通过本《隐私政策》公布的联系方式要求暂时封停您的账号。</span><strong>若您继续使用我们的产品或服务，即表示您同意受修订后的本《隐私政策》的约束。本《隐私政策》内容的最终解释权归云梯数字化人才服务平台所有。任何修订版本自更新发布之日起生效适用。</strong>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">本《隐私政策》所指的实质变化包括但不限于：</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
        </p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">2.个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">3.您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
        <p style="text-align: left;"><span
            style="color: rgb(68, 68, 68);">4.其他可能对您的个人信息权益产生重大影响的变化时；</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">5.个人信息出境情况发生变更时。</span></p>
        <p style="text-align: left;"><strong>十、如何联系我们</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">如果您对本《隐私政策》及我们对您的个人信息的处理有任何疑问、意见、建议，或您发现可能存在个人信息被泄露的情形，请通过以下方式与我们联系：</span>
        </p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">1.我们设立了个人信息保护</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">专职部门</span><span style="color: rgb(68, 68, 68);">，您可以通过其邮箱：</span><span
            style="color: rgb(100, 106, 115); background-color: rgb(245, 246, 247);">wanghuanyu@dascore.cn</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">与我们联系。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">2.我们设立了个人信息保护问题</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">专线</span><span style="color: rgb(68, 68, 68);">，您可以通过客服热线向我们反映相关问题：求职者请拨打</span><span
            style="font-family: 宋体;">400-0341101</span><span style="color: rgb(0, 0, 0);">，招聘者请拨打</span><span
            style="color: rgb(0, 0, 0); font-family: 宋体;">400-0341101。</span></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">一般情况下，我们将在15</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">天内回复</span><span style="color: rgb(68, 68, 68);">。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、工信、公安、人力资源、市场监督管理等部门进行投诉或举报。</span>
        </p>
        <p style="text-align: left;"><strong>十一、适用法律</strong></p>
        <p style="text-align: left;"><span style="color: rgb(68, 68, 68);">本《隐私政策》与</span><span
            style="color: rgb(68, 114, 196);">《</span><span style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goxieyi(1)">云梯数字化人才服务平台用户协议</span><span
            style="color: rgb(68, 114, 196);">》</span><span style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goguifan">《招聘行为管理规范》</span><span
            style="color: rgb(68, 68, 68);">共同构成您使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务的基本协议文件。本《隐私政策》适用中华人民共和国现行法律法规。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">凡因本隐私政策引起的或与本隐私政策有关的任何争议或纠纷，首先应友好协商解决，协商不成的，用户同意将按照</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;">《云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196);">用户协议》</span><span
            style="color: rgb(68, 68, 68);">第十四条的约定执行。</span></p>
      </div>
      <div v-if="id === '3'">
        <p style="text-align: center;" @click="goguifan"><strong>招聘行为管理规范</strong></p>
        <p><strong>一、前言</strong></p>
        <p><span style="color: rgb(68, 68, 68);">感谢您使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">！为打造一个严肃、纯净的招聘求职信息服务平台，向您和求职者提供更好的服务，我们就</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">上的招聘行为制定本规范。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);" @click="goguifan">1.1 本《招聘行为管理规范》（以下简称“规范”）系用户（在本规范中特指“招聘者”或有招聘需求的企业）与</span><span
            style="color: rgb(0, 0, 0);">云梯数字技术有限公司北京分公司</span><span style="color: rgb(68, 68, 68);">（以下简称“</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">公司”或“平台”或“我们”）订立的</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goxieyi(1)">《云梯数字化人才服务平台用户协议》</span><span
            style="color: rgb(68, 68, 68);">的有效组成部分，与</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;" @click="goxieyi(1)">《云梯数字化人才服务平台用户协议》</span><span
            style="color: rgb(68, 68, 68);">共同构成您与</span><span style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">公司合作的法律文件。</span></p>
        <p><span style="color: rgb(68, 68, 68);">1.2 您在使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务之前，请务必审慎阅读、充分理解本规范。当您使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务时，即表示您已阅读并同意本规范的全部内容。</span></p>
        <p><span style="color: rgb(68, 68, 68);">1.3 </span><span style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">有权根据法律法规、政策及产品需求更新本规范，请您定期访问并查看最新版本。如您在本规范更新后选择继续使用</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">服务，即表示您接受经修订后的条款和内容。</span></p>
        <p><span style="color: rgb(68, 68, 68);">1.4 本规范主要基于《中华人民共和国民法典》《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国个人信息保护法》《中华人民共和国就业促进法》《中华人民共和国劳动法》《中华人民共和国劳动合同法》《人力资源市场暂行条例》《人力资源服务机构管理规定》《互联网信息服务管理办法》《网络招聘服务管理规定》《网络信息内容生态治理规定》《互联网用户账号名称管理规定》等国家法律法规及相关规范性文件（以下简称“法律规定”）制定。</span>
        </p>
        <p><strong>二、 原则</strong></p>
        <p><span
            style="color: rgb(68, 68, 68);">2.1 用户应仅以招聘目的使用平台，不得利用平台从事违法违规活动，或以招聘为名使用平台从事其他活动，如营销推广、寻求合作等。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">2.2 用户应严格遵守法律法规及本规范的规定开展招聘工作，平台应严格遵守法律法规并依据本规范的规定对用户进行管理。</span>
        </p>
        <p><strong>三、 违规行为的认定</strong></p>
        <p><span style="color: rgb(68, 68, 68);">3.1 平台认定违规行为的主要依据：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.1 平台监测审查的结果；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.2 行政管理部门的通报、通知；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.3 司法机关的法律文书；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.4 平台其他用户的投诉、举报；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.5 新闻媒体曝光；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;3.1.6 其他合法合规渠道反馈的结果。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">3.2 平台根据法律法规及本规范的规定，结合证据材料对用户的违规行为进行认定并形成认定结果。平台根据该认定结果对用户下发管理措施。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">3.3 对于部分存在争议的案件，平台将引入大众评审的方式进行公投，并参考公投情况形成认定结果。平台根据该认定结果对用户下发管理措施。</span>
        </p>
        <p><strong>四、 违规行为的类型</strong></p>
        <p><span
            style="color: rgb(68, 68, 68);">平台根据法律法规的要求、公序良俗、社区公约及平台规则，将平台上的违规行为分为以下五类：</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">4.1 重大违法违规：用户行为存在违反法律法规禁止性规定，且可能会对平台招聘求职安全造成严重影响，需要立即采取措施避免影响进一步扩大的情况。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">4.2 一般违法违规：用户行为存在违反法律法规禁止性规定的其他情况。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">4.3 影响求职者体验：用户行为虽未违反法律法规禁止性规定，但严重影响求职者体验、平台生态，或其他不利于招聘求职环境的情况。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">4.4 不满足准入要求：用户不符合平台准入要求，如已经被列入失信名单或进入破产清算程序、无法提供相应的资质证明、合作证明等情况。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">4.5 风险职位：用户发布的职位虽未违反法律法规禁止性规定，但平台结合网络招聘特点、行业情况、平台运营成本、求职安全等因素，认为存在较高风险，或暂不适合发布的情况。</span>
        </p>
        <p><strong>五、 违规细则</strong></p>
        <p><strong>（一） 重大违法违规</strong></p>
        <p><span style="color: rgb(68, 68, 68);">5.1 严禁危害国家安全、社会稳定类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.1 反对宪法基本原则的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.2 危害国家安全、泄露国家秘密的；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.3 煽动颠覆国家政权、推翻社会主义制度、分裂国家、破坏国家统一的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.4 损害国家荣誉和利益的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.5 宣扬恐怖主义、极端主义的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.6 分裂国家、民族、政治、疆土的；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.7 贬损、恶搞、损害革命领袖、英雄烈士人物形象、名誉的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.8 不当使用党和国家领导人姓名、形象的；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.9 宣扬战争、买卖军火、交易枪支，如在职位发布、沟通聊天中提及上述敏感信息等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.1.10 其他危害国家安全、社会稳定类违法信息，或有其他违反《网络信息内容生态治理规定》第六条、第七条规定的。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.2 严禁淫秽、色情类违法违规行为，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.1 利用平台实施色情行为，如在职位发布、沟通聊天、线下面试过程中发布色情擦边信息，或有类似行为的；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.2 发布色情助理类职位，如要求照顾起居、提供按摩、住家或贴身服务等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.3 发布色情主播类职位，如要求提供大尺度、擦边表演、穿着暴露或在直播中存在不文明、挑逗、性暗示、传播色情信息等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.4 发布色情技师类职位，如提供违法、色情、擦边服务等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.5 发布色情陪玩类职位，如假借陪玩名义提供色情服务、一对一线下陪玩、同城旅游亲密陪玩陪睡等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.6 发布专职陪酒类职位，如职位限定女性且要求陪酒、工作内容为陪酒、专业陪酒、有偿一次性陪酒等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.7 发布色情模特职位，如工作内容色情或具有色情倾向、职位描述中展示大尺度、擦边内容或情趣内衣，或招聘者在介绍模特职位时有个人色情行为，如要求提供裸照、三围等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.8 为夜场、花场或其他色情场所招聘，发布色情场所相关职位，如为色情场所代招、工作内容需要前往色情场所，或需要前往异地提供色情服务等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.9 发布色情类违法信息，如卖淫、性交易、招嫖、外围或其他色情类服务信息等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.2.10 发布其他淫秽色情类信息，或有类似行为的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.3 严禁赌博类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.3.1 为赌场或其他涉赌企业招聘，发布与赌博相关职位，如工作内容包含从事赌博活动、担任荷官、发牌员，或传播推广赌博相关信息等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.3.2 为经营博彩或网络彩票业务的企业招聘，发布网络彩票售卖类职位。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.4 严禁发布毒品、精神药品、麻醉药品等违法违规信息。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.5 严禁在招聘过程中有暴力、人身攻击、伤害等行为。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">5.6 严禁宣扬封建迷信或发布包含黑社会、非法组织类违法违规信息，包括但不限于：</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.6.1 发布封建迷信类违法违规信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.6.2 为涉黑企业或非法组织招聘，发布涉黑类职位。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.7 严禁诈骗类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.7.1 实施套路贷或其他贷款类诈骗，如整容贷等；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.7.2 为经营刷单业务的企业招聘，发布刷单类职位，如职位描述中包含兼职刷单、垫付返现或其他类似内容等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.7.3 实施金融类诈骗，如职位描述中包含引导求职者投资入股、交易员、分析师类职位无需经验即可入职或其他类似内容等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.7.4 实施设局类诈骗，如以恋爱关系设局的杀猪盘诈骗、针对老年人特点设局的古董字画、股东分红或储值卡诈骗等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.7.5 以招聘为名使用任何方式实施诈骗。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.8 严禁传销或传销模式企业招聘。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.9 严禁个人信息类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.9.1 高频异常索取或骗取简历、微信、手机号等联系方式，或通过其他非平台允许的方式获取个人信息，如采取变体联系方式获取个人信息等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.9.2 在沟通面试过程中引导求职者注册与求职无关APP，或使用其他方式诱导求职者提供个人信息；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.9.3 贩卖账号，账号交易，账号或个人信息买卖；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.9.4 招聘过程中以任何方式违法违规收集个人信息。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.10 严禁违反未成年人保护规定类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.10.1 招募十六周岁以下童工；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.10.2 招募未成年人从事高危、禁止性工作，如井下作业、矿场等。</span>
        </p>
        <p><strong>（二） 一般违法违规</strong></p>
        <p><span style="color: rgb(68, 68, 68);">5.11 严禁劳动合同签署类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.11.1 不签、迟签劳动合同或劳务合同；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.11.2 </span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">录用后</span><span style="color: rgb(68, 68, 68);">恶意取消或恶意解约，如短期招录后无任何理由解约，或其他在缔约和履约过程中违法或存在严重过失的行为；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.11.3 在劳动合同中约定违法或不合理条款，如离职退款、不合理违约责任等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.12 严禁薪资类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.12.1 发布无薪资、无底薪职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.12.2 欠薪、克扣薪资；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.12.3 恶意虚高薪资、福利待遇、补助、绩效等；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.12.4 发布不合理、远超行业平均标准的不实薪资，或以其他违法方式诱导求职者投递简历等违法违规行为；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.12.5 约定低于各地最低工资标准的薪资，或设置不合理条件克扣薪资。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.13 严禁收费类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.13.1 不合理收取中介费；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.13.2 不合理收取体检费、服装费、材料费、住宿费；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.13.3 发布合伙人、加盟、推广类职位，或其他类似模式的收费职位；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.13.4 诱导强迫司机、外卖员或配送员租车、购车、贷款，或超额收费；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.13.5 其他不合理收费的。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">5.14 严禁就业歧视行为，如因种族、肤色、宗教、性别、户籍、地域、年龄等任何不合理原因差别对待求职者。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.15 严禁其他违反劳动法类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.15.1 不缴、迟缴五险一金； </span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.15.2 以任何方式、理由扣押求职者证件，如身份证、学位证，或要求担保、抵押；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.15.3 发布超过法定工时限制、工时过长、侵害劳动者休息权的职位；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.15.4 其他违反劳动法类违法违规的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.16 严禁金融监管类违法违规行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.16.1 发布信用卡套现或积分兑换类违法违规信息；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.16.2 发布AB贷、保本保收益类理财、非法贷款类违法违规信息；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.16.3 无资质销售POS机、利用网络销售POS机，或其他押金、套现相关行为；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.16.4 其他违反金融监管类法律法规的。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">5.17 严禁其他从事违法违规业务或发布违法违规信息的行为，包括但不限于：</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.1 发布资质挂靠类职位，如建筑、医疗类资质挂靠；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.2 发布代孕、非法捐精/捐卵、性别筛选、违法或违背伦理的医学试验技术类信息；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.3 发布征信修复类信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.4 发布买卖公职类信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.5 发布虚拟货币、虚拟数字财产类信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.6 非法使用他人商标、品牌；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.7 非法使用他人肖像、作品，或利用平台信息发布从事其他侵权行为；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.8 发布代考、代写类信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.9 利用平台规则漏洞牟利；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.10 发布网络水军类信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.17.11 其他违法违规类业务。</span></p>
        <p><strong>（三） 影响求职者体验</strong></p>
        <p><span style="color: rgb(68, 68, 68);">5.18 禁止在平台从事广告、引流行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.18.1 发布寻求合作类广告引流信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.18.2 发布营销类广告引流信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.18.3 发布网络兼职类广告引流信息；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.18.4 发布任何非招聘目的的广告引流信息。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.19 反对任何形式的不文明行为，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.1 口嗨，用戏谑、口无遮拦、不友好、调侃等方式声称从事违法违规行为；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.2 语言人身攻击，如辱骂等；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.3 语言骚扰，如反复与明确拒绝投递的求职者开聊、沟通无关内容等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.4 面试爽约；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.5 拒绝求职者合理问题，如面试进展；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.6 面试迟到、贬低怠慢求职者；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.19.7 其他影响求职者体验的不文明行为。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.20 反对发布不真实职位的行为，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.20.1 将已停招的职位继续在平台上展示；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.20.2 发布职位类型不准确的职位，如以获取更多曝光为目的，恶意篡改工作类型、错选职位分类、在沟通面试环节才告知求职者真实在招职位的不诚信行为等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.20.3 其他发布不真实职位的行为。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">5.21 反对发布任何不真实企业信息，如logo造假、业务造假、虚假宣传、企业介绍不实等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.22 不支持存在安全风险，已经被多次举报的企业或招聘者招聘。</span></p>
        <p><strong>（四） 不满足准入要求</strong></p>
        <p><span style="color: rgb(68, 68, 68);">5.23 不支持企业经营状态异常的企业或招聘者招聘，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.1 营业执照注销、吊销的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.2 企业、法定代表人或负责人被列入失信名单的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.3 已经破产、进入破产程序的；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.4 存在严重经营困难、倒闭或其他求职者反馈有经营异常的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.5 存在重大不良舆情的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.23.6 其他因经营状态异常不支持招聘的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.24 不支持身份异常的招聘者使用平台服务，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.24.1 使用虚拟号、国外手机号注册的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.24.2 身份异常的，如存在账号外借、非本人使用、同一账号在多个地区同时登录、虚假离职、账号异地跨城市扫码登录或其他招聘者身份虚假的情形等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.25 不支持人企关系异常的招聘者使用平台服务，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.25.1 提供虚假认证材料；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.25.2 冒充其他公司员工招聘，如中介、猎头虚假认证至代招公司名下开展招聘等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.25.3 冒充个体户异地招聘；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.25.4 其他人企关系异常的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.26 不支持设备异常的招聘者使用平台服务，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.26.1 使用第三方插件的，如插件功能存在违规收集或存储求职者简历等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.26.2 使用的设备识别号被篡改或设备已经被列为黑名单的；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.26.3 存在同一时间异地登录、非常用设备登录导致异常的，或不合理异地异常设备登录的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.26.4 其他设备异常的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.27 不支持地址异常的招聘者使用平台服务，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.27.1 填写虚假的职位地址、面试地址或存在隐瞒真实工作地址行为的；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.27.2 职位定位在风险地址的，如地址定位在境外高风险地区，或经查证属实或高度怀疑的色情窝点、诈骗窝点等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.28 不支持代招关系异常的招聘者使用平台服务，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.28.1 公司之间除总公司与分公司关系之外均不支持代招。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.28.2 人力资源服务机构无法提供与客户委托招聘授权的不支持代招；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.28.3 其他代招关系异常的。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.29 不支持资质异常的企业招聘，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.29.1 人力资源服务机构或实际从事人力资源服务的企业无法提供人力资源服务许可证、劳务派遣经营许可证的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.29.2 平台基于求职安全考虑对部分行业（如按摩/保险/网约车/货运服务/涉政府合作/其他因风险较高需要进行资质管控的）要求提供相关资质证明及合作证明，但用户无法有效提供的；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.29.3 虚假冒用他人品牌的。</span></p>
        <p><span
            style="color: rgb(68, 68, 68);">5.30 不支持已经被列入平台黑名单的企业、招聘者使用平台服务，包括但不限于：</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.30.1 身份信息已经被列入黑名单的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.30.2 登录设备已经被列入黑名单的；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.30.3 企业名称已被列入黑名单的；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.30.4 其他因此前严重违规行为已被列入黑名单不支持招聘的。</span>
        </p>
        <p><strong>（五） 风险职位</strong></p>
        <p><span style="color: rgb(68, 68, 68);">5.31 禁止发布存在风险的招生培训类职位，包括但不限于： </span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.31.1 手艺培训类职位，如在家政、美容美业、店铺学徒中有培训收费或类似表述等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.31.2 技术培训类职位，如在职位中提及视频剪辑、编程、设计培训收费或类似表述，或其他引导求职者考证等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.31.3 其他以招聘为名，实际借机开展招生培训业务的职位。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.32 禁止发布存在风险的兼职类职位，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.32.1 网络兼职类职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.32.2 兼职模特类职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.32.3 手机/手工兼职类职位；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.32.4 其他因具有较高风险，暂不支持发布的兼职类职位。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.33 禁止发布存在风险且需前往异地工作的职位，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.33.1 驻外类职位，如前往境外风险地区、从事境外博彩业务或平台暂无法核实企业真实业务内容的情况等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.33.2 出海类职位，如远洋捕捞、跟船出海等；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.33.3 其他具有高风险且需要前往异地工作的职位。</span></p>
        <p><span style="color: rgb(68, 68, 68);">5.34 禁止发布业务不合规或存在高风险的职位，包括但不限于：</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.34.1 博眼球、违反公序良俗的体验类职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.34.2 游戏陪玩类职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.34.3 中小学上门家教类职位；</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.34.4 直销或直销模式类职位；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.34.5 其他因业务模式存在不合规或高风险而不支持发布的职位。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">5.35 禁止发布工作内容不合规或存在高风险的职位，包括但不限于：</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.35.1 工作内容要求假扮异性、外国人，或其他以不正当的销售运营方式开展业务的职位；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.35.2 工作内容为从事医托、酒托、婚托，或其他以不正当方式诱导消费的职位；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.35.3 工作内容为医疗试验、器械试验的职位；</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;5.35.4 其他因从事的工作内容不合规或高风险而不支持发布的职位。</span>
        </p>
        <p><strong>六、 管理措施</strong></p>
        <p><span style="color: rgb(68, 68, 68);">平台将根据违规行为的性质及严重程度，适用如下管理措施：</span></p>
        <p><span style="color: rgb(68, 68, 68);">6.1 账号封禁类：违规账号将被封禁冻结</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.1.1 平台会根据用户违规行为的严重程度及风险确定具体冻结方式，如招聘者身份冻结、招聘者及求职者双身份冻结、停止企业服务等；</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.1.2 如果违规行为对招聘求职安全造成严重影响的，平台还将依据法律法规的要求，将违规用户在平台上已提交的认证信息列入黑名单并实施后续管控措施。违规用户被列入黑名单后将无法入驻平台，所在的企业也将无法使用平台的服务。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.2 资质核验类：违规账号将被下发资质认证材料</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.2.1 平台将根据用户违规行为的严重程度及风险确定下发的资质核验类型，如环境认证、地址认证等。平台将根据用户提交的认证材料进行核验，如该用户是否系本人登录，或用户是否已经得到企业的招聘授权等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.2.2 如用户已存在违规行为的，资质核验材料还包括承诺函，用于承诺不再从事某些业务或承诺不再出现某类违规行为。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.3 限制功能类：违规账号将被限制使用部分服务功能</span></p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.3.1 平台会根据用户违规行为的严重程度及风险确定下发的限制范围，如该用户暂时无法与平台上的求职者开聊，</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">或获取</span><span style="color: rgb(68, 68, 68);">联系方式功能等；</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.3.2 功能限制期间该用户的账号仍可以使用平台的其他基础服务。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.4 驳回删除类：违规内容将被驳回、删除或要求尽快调整修改。平台会根据法律法规及本规范的规定确定需驳回、删除的内容，如职位名称、职位描述、公司简介、薪资范围、敏感词等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">5</span><span style="color: rgb(68, 68, 68);"> 关联处理：平台对违规账号下发管理措施时，会根据用户违规行为的严重程度及风险确定是否</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">对该账号的关联账号一并下发处置</span><span
            style="color: rgb(68, 68, 68);">。</span></p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">5</span><span style="color: rgb(68, 68, 68);">.1 关联账号：指与违规用户账号之间存在关联关系的账号。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">5</span><span style="color: rgb(68, 68, 68);">.2 关联关系：平台基于网络招聘服务的特点和平台管理经验判断用户账号之间的关联关系。判断依据包括但不限于：招聘者与企业之间关联、实名认证主体关联、集团关联（公司、集团公司、关联企业）、认证材料关联、登录设备关联、地址关联等。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);"> &nbsp; &nbsp;6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">5</span><span style="color: rgb(68, 68, 68);">.3 如违规行为属于用户个人行为的，平台将对该用户的账号进行处置。如违规行为属于企业违规行为的，平台将对该用户所认证的企业、同企业下的其他用户进行处置。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">6 </span><span style="color: rgb(68, 68, 68);">平台对于多次违规或造成恶劣影响的用户将依照本规范从重或加重处理。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">7</span><span style="color: rgb(68, 68, 68);"> 用户违规行为涉嫌违法犯罪的，平台将依法向有关行政机关及司法部门举报、披露相关信息。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">6.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">8</span><span style="color: rgb(68, 68, 68);"> 用户违规行为给平台造成损失的，平台将保留追究相应损害赔偿责任或违约责任的权利。</span>
        </p>
        <p><strong>七、 通知</strong></p>
        <p><span
            style="color: rgb(68, 68, 68);">7.1 平台对存在违规的用户，会通过站内信、页面弹窗等多种方式对处理结果进行通知。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">7.2 如用户的行为属于严重违法违规，或需立即采取限制措施、避免对招聘求职安全造成严重影响的，平台有权在下发通知的同时采取管理措施。</span>
        </p>
        <p><strong>八、 申诉</strong></p>
        <p><span
            style="color: rgb(68, 68, 68);">8.1 平台充分保证用户的申诉权利。用户对管理措施有异议的，请及时通过平台申诉渠道进行反馈。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68);">8.2 平台将及时对用户的申诉进行复核和反馈。用户申诉成立的，平台将撤销相应管理措施；用户申诉不成立或未申诉的，平台将依照当前管理措施执行。</span>
        </p>
        <p><strong>九、 其他</strong></p>
        <p><span style="color: rgb(68, 68, 68);">9.1 为保障</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">的稳定及安全，任何用户不得使用任何相关招聘管理系统、插件、外挂、工具或ATS，包括但不限于北森、大易、moka、图谱、云招、e成等，访问或接入</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">系统及/或获取</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">免费及付费服务（包括但不限于登录</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">云梯数字化人才服务平台</span><span
            style="color: rgb(68, 68, 68);">账号、发布职位、浏览职位、收发简历、筛选匹配、定向推送消息、自动沟通等）。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">9.2 本平台有权根据法律法规、政策及产品需求更新本规范，并在平台内予以公布。平台将以站内消息通知的方式提醒用户更新内容，以便用户了解最新版本。</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;" @click="goguifan">本《招聘行为规范》内容的最终解释权归云梯数字化人才服务平台所有。云梯数字化人才服务平台保留随时更正、修改、更新《招聘行为规范》的权利，任何修订版本自平台更新发布之日起生效适用。</span>
        </p>
        <p><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">9.3 凡因本规范引起的或与本规范有关的任何争议或纠纷，首先应友好协商解决，协商不成的，用户同意将按照</span><span
            style="color: rgb(68, 114, 196); font-family: 宋体;">《云梯数字化人才服务平台</span><span
            style="color: rgb(68, 114, 196);">用户协议</span><span style="color: rgb(68, 114, 196); font-family: 宋体;">》</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">第十四条的约定执行。</span></p>
        <p><span style="color: rgb(68, 68, 68); font-family: 宋体;">9.4 云梯数字技术有限公司北京分公司不行使、未能及时行使或者未充分行使本规范或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响在将来行使该权利。</span><span
            style="color: rgb(68, 68, 68);">如本</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">规范</span><span style="color: rgb(68, 68, 68);">中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效且具有约束力。</span>
        </p>
        <p><strong>十、 定义</strong></p>
        <p><span
            style="color: rgb(68, 68, 68);">10.1 “招聘者”：是指已经获得企业授权，有权代表企业在平台开展招聘工作的用户。用户在平台的招聘行为应视为职务行为。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">10.2 “招聘行为”：是指用户代表其受雇企业，在平台上开展的以招聘为目的的一切行为，包括但不限于注册、登录、认证、查看开聊、账号管理、发布招聘信息、邀约面试等。平台严禁用户以招聘为名使用平台从事其他活动。</span>
        </p>
        <p><span style="color: rgb(68, 68, 68);">10.</span><span
            style="color: rgb(68, 68, 68); font-family: 宋体;">3</span><span style="color: rgb(68, 68, 68);"> “人力资源服务机构”：是指经营范围包含人力资源、劳务派遣、实际存在代招或从事人力资源、劳务派遣业务的招聘企业。</span>
        </p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
    <Footer/>
    <floatRight/>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import Footer from "@/components/footer/index.vue";

export default {
  components: {Footer, floatRight, Header},
  data() {
    return {
      id: 1,
    }
  },
  created() {
    document.documentElement.scrollTop = 0;

    //
    this.id = this.$route.query.id
  },
  watch: {
    $route(to) {
      if(to.query.id === '1') {
        this.id = '1'
      }else if(to.query.id === '2') {
        this.id = '2'
      }else{
        this.id = '3'
      }
      this.backToTop()
      console.log(to)
    }
  },
  methods: {
    goxieyi(index) {
      console.log(index)
      this.$router.push({
        path: '/agreement',
        query: {
          id: index
        }
      })
    },
    backToTop() {
      const upRoll = setInterval(() => {
        const top = document.documentElement.scrollTop; // 每次获取页面被卷去的部分
        const speed = Math.ceil(top / 10); // 每次滚动多少 （步长值）
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed; // 不在顶部 每次滚动到的位置
        } else {
          clearInterval(upRoll); // 回到顶部清除定时器
        }
      }, 20);
    },
    goguifan() {
      console.log(1111111111)
      this.$router.push({
        path: '/agreement',
        query: {
          id: 3
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.agreement {
  background: rgb(236, 243, 251);
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .content {
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    width: 1000px;
    min-height: calc(100vh - 160px);
    margin: 72px auto 24px auto;
  }
}
</style>
